/* eslint-disable react/no-danger */

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import Button from './Button';
import TextPartial from './Text';

import styles from '../styles/partials/answer.module.css';

import Bravo from '../img/bravo.png';
import Oups from '../img/oups.png';

const propTypes = {
    letter: PropTypes.string.isRequired,
    label: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.number,
    size: PropTypes.string,
    open: PropTypes.bool,
    selected: PropTypes.bool,
    valid: PropTypes.bool,
    invalid: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    isBoolean: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    label: 'Answer',
    description: null,
    value: null,
    size: null,
    open: false,
    selected: false,
    valid: false,
    invalid: false,
    isBoolean: false,
    className: null,
};

function Answer({
    letter,
    label,
    description,
    value,
    selected,
    size,
    open,
    valid,
    invalid,
    onClick,
    isBoolean,
    className,
}) {
    const onClickButton = useCallback(() => {
        if (onClick !== null) {
            onClick(value || null);
        }
    }, [value, onClick]);

    const done = valid || invalid || open || selected;

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles[size]]: size !== null,
                    [styles.valid]: valid === true && isBoolean,
                    [styles.invalid]: invalid === true && isBoolean,
                    [styles.selected]: selected === true,
                    [styles.disabled]: done,
                    [className]: className !== null,
                },
            ])}
        >
            <Button className={styles.button} onClick={onClickButton} disabled={done}>
                <div className={styles.top}>
                    <span className={styles.letter}>{letter}</span>
                    <span className={styles.label} dangerouslySetInnerHTML={{ __html: label }} />
                    {isBoolean ? (
                        <img
                            className={classNames([
                                styles.slogan,
                                {
                                    [styles.valid]: valid,
                                    [styles.invalid]: invalid,
                                    [styles.selected]: selected,
                                },
                            ])}
                            src={valid ? Bravo : Oups}
                            alt={valid ? 'Bravo' : 'Oups'}
                        />
                    ) : null}
                </div>
                {description !== null ? (
                    <TextPartial
                        className={classNames([styles.description, { [styles.open]: open }])}
                        body={description}
                    />
                ) : null}
            </Button>
        </div>
    );
}

Answer.propTypes = propTypes;
Answer.defaultProps = defaultProps;

export default Answer;
