/* eslint-disable react/jsx-props-no-spreading, react/no-danger */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from './partials/Button';
import TextPartial from './partials/Text';

// import * as AppPropTypes from '../../../lib/PropTypes';
import styles from './styles/intro.module.css';

const propTypes = {
    texts: PropTypes.shape({ body: PropTypes.string, button: PropTypes.string }),
    onNext: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    texts: { body: null, button: null },
    className: null,
};

function Intro({ texts, onNext, className }) {
    const { body = null, button = null } = texts;
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <TextPartial className={styles.description} body={body} />
            <Button className={styles.button} onClick={onNext}>
                {button}
            </Button>
        </div>
    );
}

Intro.propTypes = propTypes;
Intro.defaultProps = defaultProps;

export default Intro;
