/* eslint-disable react/jsx-props-no-spreading */
import { getComponentFromName } from '@folklore/utils';
import { useTransition, useSpring, animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'wouter';

import useElementSize from '../../hooks/useElementSize';
import { useTrackEvent } from '../../hooks/useTracking';

import data from './data';

import styles from './styles/quiz.module.css';

import lowFlare from './img/low-flare.png';
import madFlare from './img/mad-flare.png';
import dance from './img/slides/dance.png';
import dog from './img/slides/dog.png';
import drinks from './img/slides/drinks.png';
import survey from './img/slides/survey.png';
import travel from './img/slides/travel.png';
import trip from './img/slides/trip.png';

const backgroundImages = [[drinks], [survey], [dance], [travel], [dog], [trip], [null]];

const flareImages = [madFlare, lowFlare, madFlare, lowFlare, madFlare, lowFlare];

const propTypes = {
    quiz: PropTypes.shape({}),
    onAnswer: PropTypes.func,
    onEnd: PropTypes.func,
    slide: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    quiz: data,
    onAnswer: (answers, { key, answerValue = null, index = null }) => ({
        ...answers,
        [key]: { answerValue: answerValue || 0, index },
    }),
    onEnd: () => {},
    slide: 0,
    className: null,
};

function Quiz({ quiz, slide: slideIndex, onAnswer, className }) {
    const trackEvent = useTrackEvent();
    const { ref: quizRef, width: innerWidth, height: innerHeight } = useElementSize();

    const [answers, setAnswers] = useState({});
    const [index, setIndex] = useState(parseInt(slideIndex, 10) || 0);
    useEffect(() => {
        setIndex(parseInt(slideIndex, 10) || 0);
    }, [setIndex, slideIndex]);

    const [, setLocation] = useLocation();

    const { baseUrl = null, slides = [], slug, getResult = null, components = null } = quiz || {};
    const totalCount = slides.length;
    const questionsCount = slides
        ? slides.reduce((acc, slide) => {
              if (slide.qid > acc) {
                  return slide.qid;
              }
              return acc;
          }, 0)
        : 0;

    const onNext = useCallback(() => {
        if (index === 0) {
            trackEvent(`Quiz-${slug}`, 'begin');
        }
        const nextIndex = index + 1;
        setIndex(nextIndex);
        if (baseUrl !== null) {
            setLocation(`${baseUrl}${nextIndex > 0 ? `/${nextIndex}` : ''}`);
        }
        if (window) {
            window.scrollTo({ x: 0, y: 0 });
        }
    }, [index, setIndex, onAnswer, answers, setAnswers, slug, trackEvent, baseUrl, setLocation]);

    const onAnswerQuestion = useCallback(
        ({ name = null, value = null, valid = null, idx = 0 }) => {
            if (name !== null) {
                trackEvent(`Quiz-${slug}`, 'answer', `${index} - ${name} - ${value}`, value);
                setAnswers(onAnswer(answers, { key: name, answerValue: value, valid, index: idx }));
            }
        },
        [index, setIndex, onAnswer, answers, setAnswers, slug, trackEvent, baseUrl],
    );

    const onRestart = useCallback(() => {
        setAnswers({});
        setIndex(0);
        trackEvent(`Quiz-${slug}`, 'restart');
        if (baseUrl !== null) {
            setLocation(`${baseUrl}`);
        }
    }, [slug, baseUrl, setLocation, setAnswers, setIndex, trackEvent]);

    const result = getResult !== null ? getResult(answers) : null;

    useEffect(() => {
        if (index === slides.length - 1) {
            trackEvent(`Quiz-${slug}`, 'end');
        }
    }, [index, slides, slug, trackEvent]);

    const slide = slides[index] || {};
    const SlideComponent = getComponentFromName(components, slide.type, 'Intro');
    const key = `slide-${index}-${slide.type}`;
    const { ...slideProps } = slide;
    const answer = slide.qid ? answers[slide.qid] : null;
    const value = !!answer;

    const { results = {} } = slideProps || {};
    const { rank = null } = result || {};
    const finalResult = rank !== null ? results[rank] : null;

    const Slide = (
        <SlideComponent
            className={styles.slide}
            {...slideProps}
            key={key}
            value={value}
            answer={answer}
            finalResult={finalResult}
            onNext={onNext}
            onAnswerQuestion={onAnswerQuestion}
            onRestart={onRestart}
            totalCount={totalCount}
            questionsCount={questionsCount}
            currentIndex={index}
            width={innerWidth}
            height={innerHeight}
        />
    );

    const transitions = useTransition([Slide], {
        keys: ({ key: slideKey }) => slideKey,
        initial: { opacity: 0, transform: 'translate3d(0,0,0)', position: 'absolute' },
        from: { opacity: 0, transform: 'translate3d(0,-200px,0)', position: 'absolute' },
        update: { opacity: 1, transform: 'translate3d(0,0,0)', position: 'relative' },
        enter: { opacity: 1, transform: 'translate3d(0,0,0)', position: 'absolute' },
        leave: { opacity: 0, transform: 'translate3d(0,-200px,0)', position: 'absolute' },
        config: {
            mass: 1,
            tension: 100,
            friction: 20,
            clamp: true,
        },
        trail: 1000,
    });

    const { image: resultImage = null } = finalResult || {};
    backgroundImages[backgroundImages.length - 1] = [resultImage];

    const backgrounds = useTransition(backgroundImages[index], {
        keys: (src) => `${src}-${index}`,
        initial: { opacity: 0, transform: 'translate3d(0,200px,0)' },
        from: { opacity: 0, transform: 'translate3d(0,200px,0)' },
        update: { opacity: 1, transform: 'translate3d(0,0,0)' },
        enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(0,200px,0)' },
        config: {
            mass: 1,
            tension: 100,
            friction: 20,
            clamp: true,
        },
    });

    const nextHue = useMemo(
        () => (Math.random() > 0.5 ? -1 : 1) * Math.random() * index * 5,
        [index],
    );

    const background = useSpring({
        from: { filter: 'hue-rotate(0deg)' },
        to: { filter: `hue-rotate(${nextHue}deg)` },
    });

    const flares = useTransition(flareImages[index], {
        keys: (src) => `${src}-${index}`,
        initial: { opacity: 0, transform: 'translate3d(0,-200px,0)' },
        from: { opacity: 0, transform: 'translate3d(0,-200px,0)' },
        update: { opacity: 1, transform: 'translate3d(0,0,0)' },
        enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(0,-200px,0)' },
        config: {
            mass: 1,
            tension: 100,
            friction: 20,
            clamp: true,
        },
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.started]: index > 0,
                    [className]: className !== null,
                },
            ])}
            ref={quizRef}
        >
            <animated.div style={background} className={styles.backgroundLayer} />
            {transitions((style, animatedChildren) => (
                <animated.div className={styles.content} style={{ ...style, width: '100%' }}>
                    {animatedChildren}
                </animated.div>
            ))}
            {backgrounds((style, image) => (
                <animated.div className={styles.background} style={{ ...style, width: '100%' }}>
                    <img
                        src={image}
                        alt="background"
                        className={classNames([
                            styles.backgroundImage,
                            {
                                [styles.isHome]: index === 0,
                            },
                        ])}
                    />
                </animated.div>
            ))}
            {flares((style, image) => (
                <animated.div className={styles.flare} style={{ ...style, width: '100%' }}>
                    <img
                        src={image}
                        alt="background"
                        className={classNames([
                            styles.backgroundFlare,
                            {
                                [styles.isHome]: index === 0,
                            },
                        ])}
                    />
                </animated.div>
            ))}
            <div className={styles.backgroundShadow} />
            {(backgroundImages || []).map((bgs) =>
                (bgs || []).map((img) => <img src={img} className={styles.hidden} alt="preload" />),
            )}
        </div>
    );
}

Quiz.propTypes = propTypes;
Quiz.defaultProps = defaultProps;

export default Quiz;
